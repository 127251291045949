import React from "react"
import Layout from "../../components/layout"
import NewsSection from "./NewsSection"


const Demos = () => {
  return (
    <Layout>



<NewsSection></NewsSection>
      
    </Layout>
  )
}

export default Demos
